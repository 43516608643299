/* tslint:disable */
/* eslint-disable */
/**
 * everai/apps/v1/worker.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { V1KeyReference } from './V1KeyReference';
import {
    V1KeyReferenceFromJSON,
    V1KeyReferenceFromJSONTyped,
    V1KeyReferenceToJSON,
} from './V1KeyReference';

/**
 * 
 * @export
 * @interface V1ValueFrom
 */
export interface V1ValueFrom {
    /**
     * 
     * @type {V1KeyReference}
     * @memberof V1ValueFrom
     */
    secretKeyRef?: V1KeyReference;
    /**
     * 
     * @type {V1KeyReference}
     * @memberof V1ValueFrom
     */
    configMapKeyRef?: V1KeyReference;
}

/**
 * Check if a given object implements the V1ValueFrom interface.
 */
export function instanceOfV1ValueFrom(value: object): boolean {
    return true;
}

export function V1ValueFromFromJSON(json: any): V1ValueFrom {
    return V1ValueFromFromJSONTyped(json, false);
}

export function V1ValueFromFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1ValueFrom {
    if (json == null) {
        return json;
    }
    return {
        
        'secretKeyRef': json['secretKeyRef'] == null ? undefined : V1KeyReferenceFromJSON(json['secretKeyRef']),
        'configMapKeyRef': json['configMapKeyRef'] == null ? undefined : V1KeyReferenceFromJSON(json['configMapKeyRef']),
    };
}

export function V1ValueFromToJSON(value?: V1ValueFrom | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'secretKeyRef': V1KeyReferenceToJSON(value['secretKeyRef']),
        'configMapKeyRef': V1KeyReferenceToJSON(value['configMapKeyRef']),
    };
}

