/* tslint:disable */
/* eslint-disable */
/**
 * everai/apps/v1/worker.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { V1ListRequestQueuesResponseRequestQueue } from './V1ListRequestQueuesResponseRequestQueue';
import {
    V1ListRequestQueuesResponseRequestQueueFromJSON,
    V1ListRequestQueuesResponseRequestQueueFromJSONTyped,
    V1ListRequestQueuesResponseRequestQueueToJSON,
} from './V1ListRequestQueuesResponseRequestQueue';

/**
 * 
 * @export
 * @interface V1ListRequestQueuesResponse
 */
export interface V1ListRequestQueuesResponse {
    /**
     * 
     * @type {Array<V1ListRequestQueuesResponseRequestQueue>}
     * @memberof V1ListRequestQueuesResponse
     */
    queues?: Array<V1ListRequestQueuesResponseRequestQueue>;
}

/**
 * Check if a given object implements the V1ListRequestQueuesResponse interface.
 */
export function instanceOfV1ListRequestQueuesResponse(value: object): boolean {
    return true;
}

export function V1ListRequestQueuesResponseFromJSON(json: any): V1ListRequestQueuesResponse {
    return V1ListRequestQueuesResponseFromJSONTyped(json, false);
}

export function V1ListRequestQueuesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1ListRequestQueuesResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'queues': json['queues'] == null ? undefined : ((json['queues'] as Array<any>).map(V1ListRequestQueuesResponseRequestQueueFromJSON)),
    };
}

export function V1ListRequestQueuesResponseToJSON(value?: V1ListRequestQueuesResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'queues': value['queues'] == null ? undefined : ((value['queues'] as Array<any>).map(V1ListRequestQueuesResponseRequestQueueToJSON)),
    };
}

