/* tslint:disable */
/* eslint-disable */
/**
 * everai/apps/v1/worker.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AppServiceProtectAppBody,
  AppServiceSetupImageBody,
  AppServiceSetupResourcesBody,
  AppServiceSetupVolumesBody,
  AppServiceUpdateAppAuxiliaryUserBody,
  Appsv1ListResponse,
  V1App,
  V1ListAppEventsResponse,
  V1ListAppRequestStatisticResponse,
  V1ListRequestQueuesResponse,
  V1ListWorkersResponse,
} from '../models/index';
import {
    AppServiceProtectAppBodyFromJSON,
    AppServiceProtectAppBodyToJSON,
    AppServiceSetupImageBodyFromJSON,
    AppServiceSetupImageBodyToJSON,
    AppServiceSetupResourcesBodyFromJSON,
    AppServiceSetupResourcesBodyToJSON,
    AppServiceSetupVolumesBodyFromJSON,
    AppServiceSetupVolumesBodyToJSON,
    AppServiceUpdateAppAuxiliaryUserBodyFromJSON,
    AppServiceUpdateAppAuxiliaryUserBodyToJSON,
    Appsv1ListResponseFromJSON,
    Appsv1ListResponseToJSON,
    V1AppFromJSON,
    V1AppToJSON,
    V1ListAppEventsResponseFromJSON,
    V1ListAppEventsResponseToJSON,
    V1ListAppRequestStatisticResponseFromJSON,
    V1ListAppRequestStatisticResponseToJSON,
    V1ListRequestQueuesResponseFromJSON,
    V1ListRequestQueuesResponseToJSON,
    V1ListWorkersResponseFromJSON,
    V1ListWorkersResponseToJSON,
} from '../models/index';

export interface CreateAppRequest {
    namespace: string;
    app: V1App;
}

export interface DeleteAppRequest {
    namespace: string;
    name: string;
}

export interface GetAppRequest {
    namespace: string;
    name: string;
}

export interface ListAppEventsRequest {
    namespace: string;
    name: string;
    pageSize?: number;
    pageToken?: string;
    orderBy?: ListAppEventsOrderByEnum;
}

export interface ListAppRequestStatisticRequest {
    namespace: string;
    name: string;
    startTime?: string;
    endTime?: string;
    interval?: ListAppRequestStatisticIntervalEnum;
}

export interface ListAppsRequest {
    namespace: string;
}

export interface ListRequestQueuesRequest {
    namespace: string;
    name: string;
}

export interface ListWorkersRequest {
    namespace: string;
    name: string;
    showAll?: boolean;
    recentDays?: number;
}

export interface PauseAppRequest {
    namespace: string;
    name: string;
}

export interface PrivateAppRequest {
    namespace: string;
    name: string;
}

export interface ProtectAppRequest {
    namespace: string;
    name: string;
    body: AppServiceProtectAppBody;
}

export interface PublishAppRequest {
    namespace: string;
    name: string;
}

export interface ResumeAppRequest {
    namespace: string;
    name: string;
}

export interface SetupImageRequest {
    namespace: string;
    name: string;
    body: AppServiceSetupImageBody;
}

export interface SetupResourcesRequest {
    namespace: string;
    name: string;
    body: AppServiceSetupResourcesBody;
}

export interface SetupVolumesRequest {
    namespace: string;
    name: string;
    body: AppServiceSetupVolumesBody;
}

export interface UpdateAppRequest {
    namespace: string;
    name: string;
    app: V1App;
    updateMask?: string;
}

export interface UpdateAppAuxiliaryUserRequest {
    namespace: string;
    name: string;
    body: AppServiceUpdateAppAuxiliaryUserBody;
}

/**
 * AppServiceApi - interface
 * 
 * @export
 * @interface AppServiceApiInterface
 */
export interface AppServiceApiInterface {
    /**
     * 
     * @summary Create an app
     * @param {string} namespace 
     * @param {V1App} app 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppServiceApiInterface
     */
    createAppRaw(requestParameters: CreateAppRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<V1App>>;

    /**
     * Create an app
     */
    createApp(requestParameters: CreateAppRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<V1App>;

    /**
     * 
     * @summary Delete app
     * @param {string} namespace 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppServiceApiInterface
     */
    deleteAppRaw(requestParameters: DeleteAppRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>>;

    /**
     * Delete app
     */
    deleteApp(requestParameters: DeleteAppRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object>;

    /**
     * 
     * @summary Get app info
     * @param {string} namespace 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppServiceApiInterface
     */
    getAppRaw(requestParameters: GetAppRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<V1App>>;

    /**
     * Get app info
     */
    getApp(requestParameters: GetAppRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<V1App>;

    /**
     * 
     * @summary List all apps
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppServiceApiInterface
     */
    listAllAppsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Appsv1ListResponse>>;

    /**
     * List all apps
     */
    listAllApps(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Appsv1ListResponse>;

    /**
     * 
     * @summary List app events
     * @param {string} namespace 
     * @param {string} name 
     * @param {number} [pageSize] The maximum number of events to return. The service may return fewer than this value. If unspecified, at most 50 events will be returned. The maximum value is 1000; values above 1000 will be coerced to 1000.
     * @param {string} [pageToken] A page token, received from a previous &#x60;ListAppEvents&#x60; call. Provide this to retrieve the subsequent page.  When paginating, all other parameters provided to &#x60;ListAppEvents&#x60; must match the call that provided the page token.
     * @param {'OrderByUnspecified' | 'OrderByAsc' | 'OrderByDesc'} [orderBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppServiceApiInterface
     */
    listAppEventsRaw(requestParameters: ListAppEventsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<V1ListAppEventsResponse>>;

    /**
     * List app events
     */
    listAppEvents(requestParameters: ListAppEventsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<V1ListAppEventsResponse>;

    /**
     * 
     * @summary List app request statistic
     * @param {string} namespace 
     * @param {string} name 
     * @param {string} [startTime] 
     * @param {string} [endTime] 
     * @param {'INTERVAL_UNSPECIFIED' | 'INTERVAL_HOUR' | 'INTERVAL_DAY' | 'INTERVAL_MOUTH'} [interval] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppServiceApiInterface
     */
    listAppRequestStatisticRaw(requestParameters: ListAppRequestStatisticRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<V1ListAppRequestStatisticResponse>>;

    /**
     * List app request statistic
     */
    listAppRequestStatistic(requestParameters: ListAppRequestStatisticRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<V1ListAppRequestStatisticResponse>;

    /**
     * 
     * @summary List apps
     * @param {string} namespace 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppServiceApiInterface
     */
    listAppsRaw(requestParameters: ListAppsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Appsv1ListResponse>>;

    /**
     * List apps
     */
    listApps(requestParameters: ListAppsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Appsv1ListResponse>;

    /**
     * 
     * @summary List request queues
     * @param {string} namespace 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppServiceApiInterface
     */
    listRequestQueuesRaw(requestParameters: ListRequestQueuesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<V1ListRequestQueuesResponse>>;

    /**
     * List request queues
     */
    listRequestQueues(requestParameters: ListRequestQueuesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<V1ListRequestQueuesResponse>;

    /**
     * 
     * @summary List workers
     * @param {string} namespace 
     * @param {string} name 
     * @param {boolean} [showAll] 
     * @param {number} [recentDays] display the workers that have existed in the between before day and now, default 2 day, value unit is day
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppServiceApiInterface
     */
    listWorkersRaw(requestParameters: ListWorkersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<V1ListWorkersResponse>>;

    /**
     * List workers
     */
    listWorkers(requestParameters: ListWorkersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<V1ListWorkersResponse>;

    /**
     * 
     * @summary Pause the app
     * @param {string} namespace 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppServiceApiInterface
     */
    pauseAppRaw(requestParameters: PauseAppRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>>;

    /**
     * Pause the app
     */
    pauseApp(requestParameters: PauseAppRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object>;

    /**
     * 
     * @summary Private app
     * @param {string} namespace 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppServiceApiInterface
     */
    privateAppRaw(requestParameters: PrivateAppRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>>;

    /**
     * Private app
     */
    privateApp(requestParameters: PrivateAppRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object>;

    /**
     * 
     * @summary Protect app
     * @param {string} namespace 
     * @param {string} name 
     * @param {AppServiceProtectAppBody} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppServiceApiInterface
     */
    protectAppRaw(requestParameters: ProtectAppRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>>;

    /**
     * Protect app
     */
    protectApp(requestParameters: ProtectAppRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object>;

    /**
     * 
     * @summary Publish app
     * @param {string} namespace 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppServiceApiInterface
     */
    publishAppRaw(requestParameters: PublishAppRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>>;

    /**
     * Publish app
     */
    publishApp(requestParameters: PublishAppRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object>;

    /**
     * 
     * @summary Resume the app
     * @param {string} namespace 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppServiceApiInterface
     */
    resumeAppRaw(requestParameters: ResumeAppRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>>;

    /**
     * Resume the app
     */
    resumeApp(requestParameters: ResumeAppRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object>;

    /**
     * 
     * @summary Setup the image of app
     * @param {string} namespace 
     * @param {string} name 
     * @param {AppServiceSetupImageBody} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppServiceApiInterface
     */
    setupImageRaw(requestParameters: SetupImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>>;

    /**
     * Setup the image of app
     */
    setupImage(requestParameters: SetupImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object>;

    /**
     * 
     * @summary Setup the resource claim of app
     * @param {string} namespace 
     * @param {string} name 
     * @param {AppServiceSetupResourcesBody} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppServiceApiInterface
     */
    setupResourcesRaw(requestParameters: SetupResourcesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>>;

    /**
     * Setup the resource claim of app
     */
    setupResources(requestParameters: SetupResourcesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object>;

    /**
     * 
     * @summary Setup the volumes of app
     * @param {string} namespace 
     * @param {string} name 
     * @param {AppServiceSetupVolumesBody} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppServiceApiInterface
     */
    setupVolumesRaw(requestParameters: SetupVolumesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>>;

    /**
     * Setup the volumes of app
     */
    setupVolumes(requestParameters: SetupVolumesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object>;

    /**
     * 
     * @summary Update app
     * @param {string} namespace 
     * @param {string} name 
     * @param {V1App} app 
     * @param {string} [updateMask] eg: {\&quot;update_mask\&quot;: \&quot;image,volume\&quot;}
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppServiceApiInterface
     */
    updateAppRaw(requestParameters: UpdateAppRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<V1App>>;

    /**
     * Update app
     */
    updateApp(requestParameters: UpdateAppRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<V1App>;

    /**
     * 
     * @summary Update app auxiliary user
     * @param {string} namespace 
     * @param {string} name 
     * @param {AppServiceUpdateAppAuxiliaryUserBody} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppServiceApiInterface
     */
    updateAppAuxiliaryUserRaw(requestParameters: UpdateAppAuxiliaryUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>>;

    /**
     * Update app auxiliary user
     */
    updateAppAuxiliaryUser(requestParameters: UpdateAppAuxiliaryUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object>;

}

/**
 * 
 */
export class AppServiceApi extends runtime.BaseAPI implements AppServiceApiInterface {

    /**
     * Create an app
     */
    async createAppRaw(requestParameters: CreateAppRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<V1App>> {
        if (requestParameters['namespace'] == null) {
            throw new runtime.RequiredError(
                'namespace',
                'Required parameter "namespace" was null or undefined when calling createApp().'
            );
        }

        if (requestParameters['app'] == null) {
            throw new runtime.RequiredError(
                'app',
                'Required parameter "app" was null or undefined when calling createApp().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/apps/v1/namespaces/{namespace}/apps`.replace(`{${"namespace"}}`, encodeURIComponent(String(requestParameters['namespace']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: V1AppToJSON(requestParameters['app']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => V1AppFromJSON(jsonValue));
    }

    /**
     * Create an app
     */
    async createApp(requestParameters: CreateAppRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<V1App> {
        const response = await this.createAppRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete app
     */
    async deleteAppRaw(requestParameters: DeleteAppRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['namespace'] == null) {
            throw new runtime.RequiredError(
                'namespace',
                'Required parameter "namespace" was null or undefined when calling deleteApp().'
            );
        }

        if (requestParameters['name'] == null) {
            throw new runtime.RequiredError(
                'name',
                'Required parameter "name" was null or undefined when calling deleteApp().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/apps/v1/namespaces/{namespace}/apps/{name}`.replace(`{${"namespace"}}`, encodeURIComponent(String(requestParameters['namespace']))).replace(`{${"name"}}`, encodeURIComponent(String(requestParameters['name']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Delete app
     */
    async deleteApp(requestParameters: DeleteAppRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.deleteAppRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get app info
     */
    async getAppRaw(requestParameters: GetAppRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<V1App>> {
        if (requestParameters['namespace'] == null) {
            throw new runtime.RequiredError(
                'namespace',
                'Required parameter "namespace" was null or undefined when calling getApp().'
            );
        }

        if (requestParameters['name'] == null) {
            throw new runtime.RequiredError(
                'name',
                'Required parameter "name" was null or undefined when calling getApp().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/apps/v1/namespaces/{namespace}/apps/{name}`.replace(`{${"namespace"}}`, encodeURIComponent(String(requestParameters['namespace']))).replace(`{${"name"}}`, encodeURIComponent(String(requestParameters['name']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => V1AppFromJSON(jsonValue));
    }

    /**
     * Get app info
     */
    async getApp(requestParameters: GetAppRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<V1App> {
        const response = await this.getAppRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all apps
     */
    async listAllAppsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Appsv1ListResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/apps/v1/apps`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => Appsv1ListResponseFromJSON(jsonValue));
    }

    /**
     * List all apps
     */
    async listAllApps(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Appsv1ListResponse> {
        const response = await this.listAllAppsRaw(initOverrides);
        return await response.value();
    }

    /**
     * List app events
     */
    async listAppEventsRaw(requestParameters: ListAppEventsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<V1ListAppEventsResponse>> {
        if (requestParameters['namespace'] == null) {
            throw new runtime.RequiredError(
                'namespace',
                'Required parameter "namespace" was null or undefined when calling listAppEvents().'
            );
        }

        if (requestParameters['name'] == null) {
            throw new runtime.RequiredError(
                'name',
                'Required parameter "name" was null or undefined when calling listAppEvents().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageSize'] != null) {
            queryParameters['pageSize'] = requestParameters['pageSize'];
        }

        if (requestParameters['pageToken'] != null) {
            queryParameters['pageToken'] = requestParameters['pageToken'];
        }

        if (requestParameters['orderBy'] != null) {
            queryParameters['orderBy'] = requestParameters['orderBy'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/apps/v1/namespaces/{namespace}/apps/{name}/events`.replace(`{${"namespace"}}`, encodeURIComponent(String(requestParameters['namespace']))).replace(`{${"name"}}`, encodeURIComponent(String(requestParameters['name']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => V1ListAppEventsResponseFromJSON(jsonValue));
    }

    /**
     * List app events
     */
    async listAppEvents(requestParameters: ListAppEventsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<V1ListAppEventsResponse> {
        const response = await this.listAppEventsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List app request statistic
     */
    async listAppRequestStatisticRaw(requestParameters: ListAppRequestStatisticRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<V1ListAppRequestStatisticResponse>> {
        if (requestParameters['namespace'] == null) {
            throw new runtime.RequiredError(
                'namespace',
                'Required parameter "namespace" was null or undefined when calling listAppRequestStatistic().'
            );
        }

        if (requestParameters['name'] == null) {
            throw new runtime.RequiredError(
                'name',
                'Required parameter "name" was null or undefined when calling listAppRequestStatistic().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['startTime'] != null) {
            queryParameters['startTime'] = requestParameters['startTime'];
        }

        if (requestParameters['endTime'] != null) {
            queryParameters['endTime'] = requestParameters['endTime'];
        }

        if (requestParameters['interval'] != null) {
            queryParameters['interval'] = requestParameters['interval'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/apps/v1/namespaces/{namespace}/apps/{name}/request_statistic`.replace(`{${"namespace"}}`, encodeURIComponent(String(requestParameters['namespace']))).replace(`{${"name"}}`, encodeURIComponent(String(requestParameters['name']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => V1ListAppRequestStatisticResponseFromJSON(jsonValue));
    }

    /**
     * List app request statistic
     */
    async listAppRequestStatistic(requestParameters: ListAppRequestStatisticRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<V1ListAppRequestStatisticResponse> {
        const response = await this.listAppRequestStatisticRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List apps
     */
    async listAppsRaw(requestParameters: ListAppsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Appsv1ListResponse>> {
        if (requestParameters['namespace'] == null) {
            throw new runtime.RequiredError(
                'namespace',
                'Required parameter "namespace" was null or undefined when calling listApps().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/apps/v1/namespaces/{namespace}/apps`.replace(`{${"namespace"}}`, encodeURIComponent(String(requestParameters['namespace']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => Appsv1ListResponseFromJSON(jsonValue));
    }

    /**
     * List apps
     */
    async listApps(requestParameters: ListAppsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Appsv1ListResponse> {
        const response = await this.listAppsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List request queues
     */
    async listRequestQueuesRaw(requestParameters: ListRequestQueuesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<V1ListRequestQueuesResponse>> {
        if (requestParameters['namespace'] == null) {
            throw new runtime.RequiredError(
                'namespace',
                'Required parameter "namespace" was null or undefined when calling listRequestQueues().'
            );
        }

        if (requestParameters['name'] == null) {
            throw new runtime.RequiredError(
                'name',
                'Required parameter "name" was null or undefined when calling listRequestQueues().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/apps/v1/namespaces/{namespace}/apps/{name}/request_queues`.replace(`{${"namespace"}}`, encodeURIComponent(String(requestParameters['namespace']))).replace(`{${"name"}}`, encodeURIComponent(String(requestParameters['name']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => V1ListRequestQueuesResponseFromJSON(jsonValue));
    }

    /**
     * List request queues
     */
    async listRequestQueues(requestParameters: ListRequestQueuesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<V1ListRequestQueuesResponse> {
        const response = await this.listRequestQueuesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List workers
     */
    async listWorkersRaw(requestParameters: ListWorkersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<V1ListWorkersResponse>> {
        if (requestParameters['namespace'] == null) {
            throw new runtime.RequiredError(
                'namespace',
                'Required parameter "namespace" was null or undefined when calling listWorkers().'
            );
        }

        if (requestParameters['name'] == null) {
            throw new runtime.RequiredError(
                'name',
                'Required parameter "name" was null or undefined when calling listWorkers().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['showAll'] != null) {
            queryParameters['showAll'] = requestParameters['showAll'];
        }

        if (requestParameters['recentDays'] != null) {
            queryParameters['recentDays'] = requestParameters['recentDays'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/apps/v1/namespaces/{namespace}/apps/{name}/workers`.replace(`{${"namespace"}}`, encodeURIComponent(String(requestParameters['namespace']))).replace(`{${"name"}}`, encodeURIComponent(String(requestParameters['name']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => V1ListWorkersResponseFromJSON(jsonValue));
    }

    /**
     * List workers
     */
    async listWorkers(requestParameters: ListWorkersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<V1ListWorkersResponse> {
        const response = await this.listWorkersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Pause the app
     */
    async pauseAppRaw(requestParameters: PauseAppRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['namespace'] == null) {
            throw new runtime.RequiredError(
                'namespace',
                'Required parameter "namespace" was null or undefined when calling pauseApp().'
            );
        }

        if (requestParameters['name'] == null) {
            throw new runtime.RequiredError(
                'name',
                'Required parameter "name" was null or undefined when calling pauseApp().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/apps/v1/namespaces/{namespace}/apps/{name}:pause`.replace(`{${"namespace"}}`, encodeURIComponent(String(requestParameters['namespace']))).replace(`{${"name"}}`, encodeURIComponent(String(requestParameters['name']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Pause the app
     */
    async pauseApp(requestParameters: PauseAppRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.pauseAppRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Private app
     */
    async privateAppRaw(requestParameters: PrivateAppRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['namespace'] == null) {
            throw new runtime.RequiredError(
                'namespace',
                'Required parameter "namespace" was null or undefined when calling privateApp().'
            );
        }

        if (requestParameters['name'] == null) {
            throw new runtime.RequiredError(
                'name',
                'Required parameter "name" was null or undefined when calling privateApp().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/apps/v1/namespaces/{namespace}/apps/{name}/private`.replace(`{${"namespace"}}`, encodeURIComponent(String(requestParameters['namespace']))).replace(`{${"name"}}`, encodeURIComponent(String(requestParameters['name']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Private app
     */
    async privateApp(requestParameters: PrivateAppRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.privateAppRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Protect app
     */
    async protectAppRaw(requestParameters: ProtectAppRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['namespace'] == null) {
            throw new runtime.RequiredError(
                'namespace',
                'Required parameter "namespace" was null or undefined when calling protectApp().'
            );
        }

        if (requestParameters['name'] == null) {
            throw new runtime.RequiredError(
                'name',
                'Required parameter "name" was null or undefined when calling protectApp().'
            );
        }

        if (requestParameters['body'] == null) {
            throw new runtime.RequiredError(
                'body',
                'Required parameter "body" was null or undefined when calling protectApp().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/apps/v1/namespaces/{namespace}/apps/{name}/protect`.replace(`{${"namespace"}}`, encodeURIComponent(String(requestParameters['namespace']))).replace(`{${"name"}}`, encodeURIComponent(String(requestParameters['name']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AppServiceProtectAppBodyToJSON(requestParameters['body']),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Protect app
     */
    async protectApp(requestParameters: ProtectAppRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.protectAppRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Publish app
     */
    async publishAppRaw(requestParameters: PublishAppRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['namespace'] == null) {
            throw new runtime.RequiredError(
                'namespace',
                'Required parameter "namespace" was null or undefined when calling publishApp().'
            );
        }

        if (requestParameters['name'] == null) {
            throw new runtime.RequiredError(
                'name',
                'Required parameter "name" was null or undefined when calling publishApp().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/apps/v1/namespaces/{namespace}/apps/{name}/publish`.replace(`{${"namespace"}}`, encodeURIComponent(String(requestParameters['namespace']))).replace(`{${"name"}}`, encodeURIComponent(String(requestParameters['name']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Publish app
     */
    async publishApp(requestParameters: PublishAppRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.publishAppRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Resume the app
     */
    async resumeAppRaw(requestParameters: ResumeAppRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['namespace'] == null) {
            throw new runtime.RequiredError(
                'namespace',
                'Required parameter "namespace" was null or undefined when calling resumeApp().'
            );
        }

        if (requestParameters['name'] == null) {
            throw new runtime.RequiredError(
                'name',
                'Required parameter "name" was null or undefined when calling resumeApp().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/apps/v1/namespaces/{namespace}/apps/{name}:resume`.replace(`{${"namespace"}}`, encodeURIComponent(String(requestParameters['namespace']))).replace(`{${"name"}}`, encodeURIComponent(String(requestParameters['name']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Resume the app
     */
    async resumeApp(requestParameters: ResumeAppRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.resumeAppRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Setup the image of app
     */
    async setupImageRaw(requestParameters: SetupImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['namespace'] == null) {
            throw new runtime.RequiredError(
                'namespace',
                'Required parameter "namespace" was null or undefined when calling setupImage().'
            );
        }

        if (requestParameters['name'] == null) {
            throw new runtime.RequiredError(
                'name',
                'Required parameter "name" was null or undefined when calling setupImage().'
            );
        }

        if (requestParameters['body'] == null) {
            throw new runtime.RequiredError(
                'body',
                'Required parameter "body" was null or undefined when calling setupImage().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/apps/v1/namespaces/{namespace}/apps/{name}/image:setup`.replace(`{${"namespace"}}`, encodeURIComponent(String(requestParameters['namespace']))).replace(`{${"name"}}`, encodeURIComponent(String(requestParameters['name']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AppServiceSetupImageBodyToJSON(requestParameters['body']),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Setup the image of app
     */
    async setupImage(requestParameters: SetupImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.setupImageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Setup the resource claim of app
     */
    async setupResourcesRaw(requestParameters: SetupResourcesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['namespace'] == null) {
            throw new runtime.RequiredError(
                'namespace',
                'Required parameter "namespace" was null or undefined when calling setupResources().'
            );
        }

        if (requestParameters['name'] == null) {
            throw new runtime.RequiredError(
                'name',
                'Required parameter "name" was null or undefined when calling setupResources().'
            );
        }

        if (requestParameters['body'] == null) {
            throw new runtime.RequiredError(
                'body',
                'Required parameter "body" was null or undefined when calling setupResources().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/apps/v1/namespaces/{namespace}/apps/{name}/resource:setup`.replace(`{${"namespace"}}`, encodeURIComponent(String(requestParameters['namespace']))).replace(`{${"name"}}`, encodeURIComponent(String(requestParameters['name']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AppServiceSetupResourcesBodyToJSON(requestParameters['body']),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Setup the resource claim of app
     */
    async setupResources(requestParameters: SetupResourcesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.setupResourcesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Setup the volumes of app
     */
    async setupVolumesRaw(requestParameters: SetupVolumesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['namespace'] == null) {
            throw new runtime.RequiredError(
                'namespace',
                'Required parameter "namespace" was null or undefined when calling setupVolumes().'
            );
        }

        if (requestParameters['name'] == null) {
            throw new runtime.RequiredError(
                'name',
                'Required parameter "name" was null or undefined when calling setupVolumes().'
            );
        }

        if (requestParameters['body'] == null) {
            throw new runtime.RequiredError(
                'body',
                'Required parameter "body" was null or undefined when calling setupVolumes().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/apps/v1/namespaces/{namespace}/apps/{name}/volumes:setup`.replace(`{${"namespace"}}`, encodeURIComponent(String(requestParameters['namespace']))).replace(`{${"name"}}`, encodeURIComponent(String(requestParameters['name']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AppServiceSetupVolumesBodyToJSON(requestParameters['body']),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Setup the volumes of app
     */
    async setupVolumes(requestParameters: SetupVolumesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.setupVolumesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update app
     */
    async updateAppRaw(requestParameters: UpdateAppRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<V1App>> {
        if (requestParameters['namespace'] == null) {
            throw new runtime.RequiredError(
                'namespace',
                'Required parameter "namespace" was null or undefined when calling updateApp().'
            );
        }

        if (requestParameters['name'] == null) {
            throw new runtime.RequiredError(
                'name',
                'Required parameter "name" was null or undefined when calling updateApp().'
            );
        }

        if (requestParameters['app'] == null) {
            throw new runtime.RequiredError(
                'app',
                'Required parameter "app" was null or undefined when calling updateApp().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['updateMask'] != null) {
            queryParameters['updateMask'] = requestParameters['updateMask'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/apps/v1/namespaces/{namespace}/apps/{name}`.replace(`{${"namespace"}}`, encodeURIComponent(String(requestParameters['namespace']))).replace(`{${"name"}}`, encodeURIComponent(String(requestParameters['name']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: V1AppToJSON(requestParameters['app']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => V1AppFromJSON(jsonValue));
    }

    /**
     * Update app
     */
    async updateApp(requestParameters: UpdateAppRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<V1App> {
        const response = await this.updateAppRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update app auxiliary user
     */
    async updateAppAuxiliaryUserRaw(requestParameters: UpdateAppAuxiliaryUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['namespace'] == null) {
            throw new runtime.RequiredError(
                'namespace',
                'Required parameter "namespace" was null or undefined when calling updateAppAuxiliaryUser().'
            );
        }

        if (requestParameters['name'] == null) {
            throw new runtime.RequiredError(
                'name',
                'Required parameter "name" was null or undefined when calling updateAppAuxiliaryUser().'
            );
        }

        if (requestParameters['body'] == null) {
            throw new runtime.RequiredError(
                'body',
                'Required parameter "body" was null or undefined when calling updateAppAuxiliaryUser().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/apps/v1/namespaces/{namespace}/apps/{name}/auxiliary-user`.replace(`{${"namespace"}}`, encodeURIComponent(String(requestParameters['namespace']))).replace(`{${"name"}}`, encodeURIComponent(String(requestParameters['name']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AppServiceUpdateAppAuxiliaryUserBodyToJSON(requestParameters['body']),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Update app auxiliary user
     */
    async updateAppAuxiliaryUser(requestParameters: UpdateAppAuxiliaryUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.updateAppAuxiliaryUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const ListAppEventsOrderByEnum = {
    OrderByUnspecified: 'OrderByUnspecified',
    OrderByAsc: 'OrderByAsc',
    OrderByDesc: 'OrderByDesc'
} as const;
export type ListAppEventsOrderByEnum = typeof ListAppEventsOrderByEnum[keyof typeof ListAppEventsOrderByEnum];
/**
 * @export
 */
export const ListAppRequestStatisticIntervalEnum = {
    Unspecified: 'INTERVAL_UNSPECIFIED',
    Hour: 'INTERVAL_HOUR',
    Day: 'INTERVAL_DAY',
    Mouth: 'INTERVAL_MOUTH'
} as const;
export type ListAppRequestStatisticIntervalEnum = typeof ListAppRequestStatisticIntervalEnum[keyof typeof ListAppRequestStatisticIntervalEnum];
