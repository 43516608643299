import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider } from "react-router-dom";
import router from "./router";
import "./i18n";
import "react-toastify/dist/ReactToastify.css";
import { ReactComponent as DrakSuccessIcon } from "./assets/icons/toast/dark-success.svg";
import { ReactComponent as DrakErrorIcon } from "./assets/icons/toast/dark-error.svg";
import { ReactComponent as DrakWarnIcon } from "./assets/icons/toast/dark-warn.svg";
import { ReactComponent as DrakInfoIcon } from "./assets/icons/toast/dark-info.svg";
import { ReactComponent as LightSuccessIcon } from "./assets/icons/toast/light-success.svg";
import { ReactComponent as LightErrorIcon } from "./assets/icons/toast/light-error.svg";
import { ReactComponent as LightWarnIcon } from "./assets/icons/toast/light-warn.svg";
import { ReactComponent as LightInfoIcon } from "./assets/icons/toast/light-info.svg";
import { ReactComponent as CloseIcon } from "./assets/icons/toast/close.svg";

async function enableMocking() {
  console.log("process.env.REACT_APP_ENV", process.env.REACT_APP_ENV);
  console.log("process.env.REACT_APP_MOCK", process.env.REACT_APP_MOCK);
  if (process.env.REACT_APP_MOCK === "none") {
    return;
  }

  const { worker } = await import("./mocks/browser");

  // `worker.start()` returns a Promise that resolves
  // once the Service Worker is up and ready to intercept requests.
  return worker.start();
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

const customIcons: any =
  localStorage.getItem("theme") === "light"
    ? {
        success: <LightSuccessIcon />,
        error: <LightErrorIcon />,
        warning: <LightWarnIcon />,
        info: <LightInfoIcon />,
      }
    : {
        success: <DrakSuccessIcon />,
        error: <DrakErrorIcon />,
        warning: <DrakWarnIcon />,
        info: <DrakInfoIcon />,
      };

// redux toolkit
import { Provider } from "react-redux";
import store from "./store";
import { initEcharts } from "./utils/echartsConfig";
import { Slide, toast, ToastContainer } from "react-toastify";

initEcharts();

const CustomCloseButton = () => {
  return <CloseIcon className="shrink-0" onClick={() => toast.dismiss()} />;
};

enableMocking().then(() => {
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <RouterProvider router={router} />
        <ToastContainer
          transition={Slide}
          hideProgressBar={true}
          closeButton={<CustomCloseButton />}
          position="top-right"
          icon={(icon) => customIcons[`${icon.type}`]}
          autoClose={2000}
          toastClassName={(context) =>
            `${
              localStorage.getItem("theme") === "light"
                ? " bg-ever-gray-0  text-ever-gray-950 shadow-md"
                : " border-ever-white-opacity-12 bg-ever-gray-950 text-ever-gray-50 shadow-none"
            } ${"relative flex p-1 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer border text-sm"}`
          }
        />
      </Provider>
    </React.StrictMode>,
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
