/* tslint:disable */
/* eslint-disable */
/**
 * everai/apps/v1/worker.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VolumeItem
 */
export interface VolumeItem {
    /**
     * 
     * @type {string}
     * @memberof VolumeItem
     */
    key?: string;
    /**
     * 
     * @type {string}
     * @memberof VolumeItem
     */
    path?: string;
}

/**
 * Check if a given object implements the VolumeItem interface.
 */
export function instanceOfVolumeItem(value: object): boolean {
    return true;
}

export function VolumeItemFromJSON(json: any): VolumeItem {
    return VolumeItemFromJSONTyped(json, false);
}

export function VolumeItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): VolumeItem {
    if (json == null) {
        return json;
    }
    return {
        
        'key': json['key'] == null ? undefined : json['key'],
        'path': json['path'] == null ? undefined : json['path'],
    };
}

export function VolumeItemToJSON(value?: VolumeItem | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'key': value['key'],
        'path': value['path'],
    };
}

