/* tslint:disable */
/* eslint-disable */
/**
 * everai/apps/v1/worker.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface V1RequestStatistic
 */
export interface V1RequestStatistic {
    /**
     * 
     * @type {string}
     * @memberof V1RequestStatistic
     */
    userName?: string;
    /**
     * 
     * @type {number}
     * @memberof V1RequestStatistic
     */
    totalRequest?: number;
    /**
     * 
     * @type {number}
     * @memberof V1RequestStatistic
     */
    successRequest?: number;
    /**
     * 
     * @type {number}
     * @memberof V1RequestStatistic
     */
    failedRequest?: number;
}

/**
 * Check if a given object implements the V1RequestStatistic interface.
 */
export function instanceOfV1RequestStatistic(value: object): boolean {
    return true;
}

export function V1RequestStatisticFromJSON(json: any): V1RequestStatistic {
    return V1RequestStatisticFromJSONTyped(json, false);
}

export function V1RequestStatisticFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1RequestStatistic {
    if (json == null) {
        return json;
    }
    return {
        
        'userName': json['userName'] == null ? undefined : json['userName'],
        'totalRequest': json['totalRequest'] == null ? undefined : json['totalRequest'],
        'successRequest': json['successRequest'] == null ? undefined : json['successRequest'],
        'failedRequest': json['failedRequest'] == null ? undefined : json['failedRequest'],
    };
}

export function V1RequestStatisticToJSON(value?: V1RequestStatistic | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'userName': value['userName'],
        'totalRequest': value['totalRequest'],
        'successRequest': value['successRequest'],
        'failedRequest': value['failedRequest'],
    };
}

