// counterSlice.ts 文件

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { V1LoginWithProviderResponse } from "../../services/iam";
import { API_AUTHN } from "../../services/request-interceptor";

interface loginState {
  userInfo: V1LoginWithProviderResponse | undefined;
}

const initialState: loginState = {
  userInfo: undefined,
};

// export const fetchUserInfo = createAsyncThunk(
//   "login/fetchUserInfo",
//   async (payload: { provider: string; codeParam: string }) => {
//     const { provider, codeParam } = payload;
//     const res: V1LoginWithProviderResponse = await API_AUTHZ.loginWithProvider({
//       body: {
//         provider: provider!,
//         code: codeParam,
//       },
//     });

//     return res;
//   },
// );

// 创建一个 Slice
export const loginSlice = createSlice({
  name: "login",
  initialState,
  // 定义 reducers 并生成关联的操作
  reducers: {
    changeUserInfoAction(state, { payload }) {
      console.log("changeUserInfoAction", payload);
      state.userInfo = payload;
    },
  },

  // extraReducers: (builder) => {
  //   builder.addCase(fetchUserInfo.fulfilled, (state, { payload }) => {
  //     console.log("payload", payload);
  //     state.userInfo = payload;
  //   });
  // },
});
// 导出加减的方法
export const { changeUserInfoAction } = loginSlice.actions;

// 默认导出
export default loginSlice.reducer;
