/* tslint:disable */
/* eslint-disable */
/**
 * everai/apps/v1/worker.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const WorkerWorkerStatus = {
    Unspecified: 'STATUS_UNSPECIFIED',
    Initialized: 'STATUS_INITIALIZED',
    Pending: 'STATUS_PENDING',
    Running: 'STATUS_RUNNING',
    Terminating: 'STATUS_TERMINATING',
    Error: 'STATUS_ERROR',
    Unavailable: 'STATUS_UNAVAILABLE',
    Terminated: 'STATUS_TERMINATED',
    Created: 'STATUS_CREATED'
} as const;
export type WorkerWorkerStatus = typeof WorkerWorkerStatus[keyof typeof WorkerWorkerStatus];


export function WorkerWorkerStatusFromJSON(json: any): WorkerWorkerStatus {
    return WorkerWorkerStatusFromJSONTyped(json, false);
}

export function WorkerWorkerStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkerWorkerStatus {
    return json as WorkerWorkerStatus;
}

export function WorkerWorkerStatusToJSON(value?: WorkerWorkerStatus | null): any {
    return value as any;
}

