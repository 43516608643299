/* tslint:disable */
/* eslint-disable */
/**
 * everai/apps/v1/worker.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AppServiceSetupImageBody
 */
export interface AppServiceSetupImageBody {
    /**
     * 
     * @type {string}
     * @memberof AppServiceSetupImageBody
     */
    image: string;
}

/**
 * Check if a given object implements the AppServiceSetupImageBody interface.
 */
export function instanceOfAppServiceSetupImageBody(value: object): boolean {
    if (!('image' in value)) return false;
    return true;
}

export function AppServiceSetupImageBodyFromJSON(json: any): AppServiceSetupImageBody {
    return AppServiceSetupImageBodyFromJSONTyped(json, false);
}

export function AppServiceSetupImageBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AppServiceSetupImageBody {
    if (json == null) {
        return json;
    }
    return {
        
        'image': json['image'],
    };
}

export function AppServiceSetupImageBodyToJSON(value?: AppServiceSetupImageBody | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'image': value['image'],
    };
}

