import login from "./login.json";
import home from "./home.json";
import sideMenu from "./sideMenu.json";
import billing from "./billing.json";
import profile from "./profile.json";
import secret from "./secret.json";
import token from "./token.json";
import common from "./common.json";
import storage from "./storage.json";
import application from "./application.json";
import logger from "./logger.json";
import overview from "./overview.json";
import configMaps from "./configMaps.json";
import errorPage from "./errorPage.json";
export default {
  login,
  home,
  sideMenu,
  billing,
  profile,
  secret,
  token,
  common,
  storage,
  application,
  logger,
  overview,
  configMaps,
  errorPage,
};
