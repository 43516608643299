/* tslint:disable */
/* eslint-disable */
/**
 * everai/apps/v1/worker.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { V1RequestStatistic } from './V1RequestStatistic';
import {
    V1RequestStatisticFromJSON,
    V1RequestStatisticFromJSONTyped,
    V1RequestStatisticToJSON,
} from './V1RequestStatistic';

/**
 * 
 * @export
 * @interface V1RequestStatistics
 */
export interface V1RequestStatistics {
    /**
     * 
     * @type {Array<V1RequestStatistic>}
     * @memberof V1RequestStatistics
     */
    requestStatistics?: Array<V1RequestStatistic>;
}

/**
 * Check if a given object implements the V1RequestStatistics interface.
 */
export function instanceOfV1RequestStatistics(value: object): boolean {
    return true;
}

export function V1RequestStatisticsFromJSON(json: any): V1RequestStatistics {
    return V1RequestStatisticsFromJSONTyped(json, false);
}

export function V1RequestStatisticsFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1RequestStatistics {
    if (json == null) {
        return json;
    }
    return {
        
        'requestStatistics': json['requestStatistics'] == null ? undefined : ((json['requestStatistics'] as Array<any>).map(V1RequestStatisticFromJSON)),
    };
}

export function V1RequestStatisticsToJSON(value?: V1RequestStatistics | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'requestStatistics': value['requestStatistics'] == null ? undefined : ((value['requestStatistics'] as Array<any>).map(V1RequestStatisticToJSON)),
    };
}

