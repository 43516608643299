/* tslint:disable */
/* eslint-disable */
/**
 * everai/apps/v1/worker.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { VolumeConfigMapItem } from './VolumeConfigMapItem';
import {
    VolumeConfigMapItemFromJSON,
    VolumeConfigMapItemFromJSONTyped,
    VolumeConfigMapItemToJSON,
} from './VolumeConfigMapItem';
import type { VolumeSecretItem } from './VolumeSecretItem';
import {
    VolumeSecretItemFromJSON,
    VolumeSecretItemFromJSONTyped,
    VolumeSecretItemToJSON,
} from './VolumeSecretItem';
import type { VolumeVolumeItem } from './VolumeVolumeItem';
import {
    VolumeVolumeItemFromJSON,
    VolumeVolumeItemFromJSONTyped,
    VolumeVolumeItemToJSON,
} from './VolumeVolumeItem';

/**
 * 
 * @export
 * @interface V1Volume
 */
export interface V1Volume {
    /**
     * 
     * @type {string}
     * @memberof V1Volume
     */
    name: string;
    /**
     * 
     * @type {VolumeVolumeItem}
     * @memberof V1Volume
     */
    volume?: VolumeVolumeItem;
    /**
     * 
     * @type {VolumeSecretItem}
     * @memberof V1Volume
     */
    secret?: VolumeSecretItem;
    /**
     * 
     * @type {VolumeConfigMapItem}
     * @memberof V1Volume
     */
    configMap?: VolumeConfigMapItem;
}

/**
 * Check if a given object implements the V1Volume interface.
 */
export function instanceOfV1Volume(value: object): boolean {
    if (!('name' in value)) return false;
    return true;
}

export function V1VolumeFromJSON(json: any): V1Volume {
    return V1VolumeFromJSONTyped(json, false);
}

export function V1VolumeFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1Volume {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'volume': json['volume'] == null ? undefined : VolumeVolumeItemFromJSON(json['volume']),
        'secret': json['secret'] == null ? undefined : VolumeSecretItemFromJSON(json['secret']),
        'configMap': json['configMap'] == null ? undefined : VolumeConfigMapItemFromJSON(json['configMap']),
    };
}

export function V1VolumeToJSON(value?: V1Volume | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'volume': VolumeVolumeItemToJSON(value['volume']),
        'secret': VolumeSecretItemToJSON(value['secret']),
        'configMap': VolumeConfigMapItemToJSON(value['configMap']),
    };
}

