/* tslint:disable */
/* eslint-disable */
/**
 * everai/apps/v1/worker.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AutoscalerDecoratorsItem } from './AutoscalerDecoratorsItem';
import {
    AutoscalerDecoratorsItemFromJSON,
    AutoscalerDecoratorsItemFromJSONTyped,
    AutoscalerDecoratorsItemToJSON,
} from './AutoscalerDecoratorsItem';

/**
 * 
 * @export
 * @interface AutoscalerDecorators
 */
export interface AutoscalerDecorators {
    /**
     * 
     * @type {Array<AutoscalerDecoratorsItem>}
     * @memberof AutoscalerDecorators
     */
    arguments?: Array<AutoscalerDecoratorsItem>;
    /**
     * 
     * @type {Array<AutoscalerDecoratorsItem>}
     * @memberof AutoscalerDecorators
     */
    factors?: Array<AutoscalerDecoratorsItem>;
}

/**
 * Check if a given object implements the AutoscalerDecorators interface.
 */
export function instanceOfAutoscalerDecorators(value: object): boolean {
    return true;
}

export function AutoscalerDecoratorsFromJSON(json: any): AutoscalerDecorators {
    return AutoscalerDecoratorsFromJSONTyped(json, false);
}

export function AutoscalerDecoratorsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AutoscalerDecorators {
    if (json == null) {
        return json;
    }
    return {
        
        'arguments': json['arguments'] == null ? undefined : ((json['arguments'] as Array<any>).map(AutoscalerDecoratorsItemFromJSON)),
        'factors': json['factors'] == null ? undefined : ((json['factors'] as Array<any>).map(AutoscalerDecoratorsItemFromJSON)),
    };
}

export function AutoscalerDecoratorsToJSON(value?: AutoscalerDecorators | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'arguments': value['arguments'] == null ? undefined : ((value['arguments'] as Array<any>).map(AutoscalerDecoratorsItemToJSON)),
        'factors': value['factors'] == null ? undefined : ((value['factors'] as Array<any>).map(AutoscalerDecoratorsItemToJSON)),
    };
}

