/* tslint:disable */
/* eslint-disable */
/**
 * everai/apps/v1/worker.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { V1Worker } from './V1Worker';
import {
    V1WorkerFromJSON,
    V1WorkerFromJSONTyped,
    V1WorkerToJSON,
} from './V1Worker';

/**
 * 
 * @export
 * @interface V1ListWorkersResponse
 */
export interface V1ListWorkersResponse {
    /**
     * 
     * @type {Array<V1Worker>}
     * @memberof V1ListWorkersResponse
     */
    workers?: Array<V1Worker>;
    /**
     * 
     * @type {number}
     * @memberof V1ListWorkersResponse
     */
    queueSize?: number;
    /**
     * 
     * @type {string}
     * @memberof V1ListWorkersResponse
     */
    queueName?: string;
    /**
     * 
     * @type {Date}
     * @memberof V1ListWorkersResponse
     */
    lastScaleUpTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof V1ListWorkersResponse
     */
    lastScaleDownDeleteTime?: Date;
    /**
     * 
     * @type {number}
     * @memberof V1ListWorkersResponse
     */
    maxWorkers?: number;
}

/**
 * Check if a given object implements the V1ListWorkersResponse interface.
 */
export function instanceOfV1ListWorkersResponse(value: object): boolean {
    return true;
}

export function V1ListWorkersResponseFromJSON(json: any): V1ListWorkersResponse {
    return V1ListWorkersResponseFromJSONTyped(json, false);
}

export function V1ListWorkersResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1ListWorkersResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'workers': json['workers'] == null ? undefined : ((json['workers'] as Array<any>).map(V1WorkerFromJSON)),
        'queueSize': json['queueSize'] == null ? undefined : json['queueSize'],
        'queueName': json['queueName'] == null ? undefined : json['queueName'],
        'lastScaleUpTime': json['lastScaleUpTime'] == null ? undefined : (new Date(json['lastScaleUpTime'])),
        'lastScaleDownDeleteTime': json['lastScaleDownDeleteTime'] == null ? undefined : (new Date(json['lastScaleDownDeleteTime'])),
        'maxWorkers': json['maxWorkers'] == null ? undefined : json['maxWorkers'],
    };
}

export function V1ListWorkersResponseToJSON(value?: V1ListWorkersResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'workers': value['workers'] == null ? undefined : ((value['workers'] as Array<any>).map(V1WorkerToJSON)),
        'queueSize': value['queueSize'],
        'queueName': value['queueName'],
        'lastScaleUpTime': value['lastScaleUpTime'] == null ? undefined : ((value['lastScaleUpTime']).toISOString()),
        'lastScaleDownDeleteTime': value['lastScaleDownDeleteTime'] == null ? undefined : ((value['lastScaleDownDeleteTime']).toISOString()),
        'maxWorkers': value['maxWorkers'],
    };
}

