import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface dashboardState {
  currentTheme: string;
}

const initialState: dashboardState = {
  currentTheme: localStorage.getItem("theme") || "dark",
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    changeCurrentThemeAction(state, action: PayloadAction<string>) {
      console.log("changeAppListAction", action.payload);
      state.currentTheme = action.payload;
    },
  },
});

export const { changeCurrentThemeAction } = dashboardSlice.actions;

export default dashboardSlice.reducer;
