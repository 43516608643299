/* tslint:disable */
/* eslint-disable */
/**
 * everai/apps/v1/worker.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { V1RequestStatistics } from './V1RequestStatistics';
import {
    V1RequestStatisticsFromJSON,
    V1RequestStatisticsFromJSONTyped,
    V1RequestStatisticsToJSON,
} from './V1RequestStatistics';

/**
 * 
 * @export
 * @interface V1ListAppRequestStatisticResponse
 */
export interface V1ListAppRequestStatisticResponse {
    /**
     * 
     * @type {{ [key: string]: V1RequestStatistics; }}
     * @memberof V1ListAppRequestStatisticResponse
     */
    requestStatistic?: { [key: string]: V1RequestStatistics; };
}

/**
 * Check if a given object implements the V1ListAppRequestStatisticResponse interface.
 */
export function instanceOfV1ListAppRequestStatisticResponse(value: object): boolean {
    return true;
}

export function V1ListAppRequestStatisticResponseFromJSON(json: any): V1ListAppRequestStatisticResponse {
    return V1ListAppRequestStatisticResponseFromJSONTyped(json, false);
}

export function V1ListAppRequestStatisticResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1ListAppRequestStatisticResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'requestStatistic': json['requestStatistic'] == null ? undefined : (mapValues(json['requestStatistic'], V1RequestStatisticsFromJSON)),
    };
}

export function V1ListAppRequestStatisticResponseToJSON(value?: V1ListAppRequestStatisticResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'requestStatistic': value['requestStatistic'] == null ? undefined : (mapValues(value['requestStatistic'], V1RequestStatisticsToJSON)),
    };
}

