/* tslint:disable */
/* eslint-disable */
/**
 * everai/apps/v1/worker.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { V1Resource } from './V1Resource';
import {
    V1ResourceFromJSON,
    V1ResourceFromJSONTyped,
    V1ResourceToJSON,
} from './V1Resource';

/**
 * 
 * @export
 * @interface AppServiceSetupResourcesBody
 */
export interface AppServiceSetupResourcesBody {
    /**
     * 
     * @type {V1Resource}
     * @memberof AppServiceSetupResourcesBody
     */
    resource: V1Resource;
}

/**
 * Check if a given object implements the AppServiceSetupResourcesBody interface.
 */
export function instanceOfAppServiceSetupResourcesBody(value: object): boolean {
    if (!('resource' in value)) return false;
    return true;
}

export function AppServiceSetupResourcesBodyFromJSON(json: any): AppServiceSetupResourcesBody {
    return AppServiceSetupResourcesBodyFromJSONTyped(json, false);
}

export function AppServiceSetupResourcesBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AppServiceSetupResourcesBody {
    if (json == null) {
        return json;
    }
    return {
        
        'resource': V1ResourceFromJSON(json['resource']),
    };
}

export function AppServiceSetupResourcesBodyToJSON(value?: AppServiceSetupResourcesBody | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'resource': V1ResourceToJSON(value['resource']),
    };
}

