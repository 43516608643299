/* tslint:disable */
/* eslint-disable */
/**
 * everai/apps/v1/worker.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface V1Exec
 */
export interface V1Exec {
    /**
     * 
     * @type {Array<string>}
     * @memberof V1Exec
     */
    command?: Array<string>;
}

/**
 * Check if a given object implements the V1Exec interface.
 */
export function instanceOfV1Exec(value: object): boolean {
    return true;
}

export function V1ExecFromJSON(json: any): V1Exec {
    return V1ExecFromJSONTyped(json, false);
}

export function V1ExecFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1Exec {
    if (json == null) {
        return json;
    }
    return {
        
        'command': json['command'] == null ? undefined : json['command'],
    };
}

export function V1ExecToJSON(value?: V1Exec | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'command': value['command'],
    };
}

