import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en from "./en-US";
import zh from "./zh-CN";

i18n
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    ns: [
      "home",
      "login",
      "sideMenu",
      "billing",
      "profile",
      "secret",
      "token",
      "common",
      "storage",
      "application",
      "logger",
      "errorPage",
    ],
    resources: {
      en,
      zh,
    },
    fallbackLng:
      localStorage.getItem("language") ||
      (navigator.language === "zh-CN" ? "zh" : "en"),
    debug: true,
    react: {
      useSuspense: false,
    },
    detection: {
      order: ["querystring", "navigator", "localStorage"],
      lookupQuerystring: "lang",
    },
  });

export default i18n;
