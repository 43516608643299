/* tslint:disable */
/* eslint-disable */
/**
 * everai/apps/v1/worker.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { V1CPUConstraint } from './V1CPUConstraint';
import {
    V1CPUConstraintFromJSON,
    V1CPUConstraintFromJSONTyped,
    V1CPUConstraintToJSON,
} from './V1CPUConstraint';

/**
 * 
 * @export
 * @interface V1ResourceFilter
 */
export interface V1ResourceFilter {
    /**
     * 
     * @type {Array<string>}
     * @memberof V1ResourceFilter
     */
    gpu?: Array<string>;
    /**
     * 
     * @type {V1CPUConstraint}
     * @memberof V1ResourceFilter
     */
    cpu?: V1CPUConstraint;
    /**
     * 
     * @type {Array<string>}
     * @memberof V1ResourceFilter
     */
    regions?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof V1ResourceFilter
     */
    cuda?: string;
    /**
     * 
     * @type {string}
     * @memberof V1ResourceFilter
     */
    nvidia?: string;
}

/**
 * Check if a given object implements the V1ResourceFilter interface.
 */
export function instanceOfV1ResourceFilter(value: object): boolean {
    return true;
}

export function V1ResourceFilterFromJSON(json: any): V1ResourceFilter {
    return V1ResourceFilterFromJSONTyped(json, false);
}

export function V1ResourceFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1ResourceFilter {
    if (json == null) {
        return json;
    }
    return {
        
        'gpu': json['gpu'] == null ? undefined : json['gpu'],
        'cpu': json['cpu'] == null ? undefined : V1CPUConstraintFromJSON(json['cpu']),
        'regions': json['regions'] == null ? undefined : json['regions'],
        'cuda': json['cuda'] == null ? undefined : json['cuda'],
        'nvidia': json['nvidia'] == null ? undefined : json['nvidia'],
    };
}

export function V1ResourceFilterToJSON(value?: V1ResourceFilter | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'gpu': value['gpu'],
        'cpu': V1CPUConstraintToJSON(value['cpu']),
        'regions': value['regions'],
        'cuda': value['cuda'],
        'nvidia': value['nvidia'],
    };
}

