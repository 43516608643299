import DarkErrorIcon from "@/assets/imgs/errorPage/dark_error.png";
import LightErrorIcon from "@/assets/imgs/errorPage/light_error.png";
import { useAppSelector } from "@/store/hook";
import { useTranslation } from "react-i18next";

const ErrorFallback = ({
  error,
  resetErrorBoundary,
}: {
  error: any;
  resetErrorBoundary: any;
}) => {
  const { t } = useTranslation(["errorPage"]);
  const { currentTheme } = useAppSelector((state) => {
    return state.dashboard;
  });
  return (
    <div className="flex min-h-screen justify-center bg-ever-gray-0 dark:bg-ever-gray-950">
      <div className="mt-36 flex flex-col items-center lg:mt-48">
        <img
          src={currentTheme === "light" ? LightErrorIcon : DarkErrorIcon}
          alt=""
          className="w-[300px] lg:w-[522px]"
        />
        <div className="mt-6 text-xl font-bold text-ever-gray-950 dark:text-ever-gray-50">
          {t("errorPage:Oops")}
        </div>
        <div className="mt-1 text-sm text-ever-gray-950 dark:text-ever-gray-50">
          {t("errorPage:Oops.tip")}
        </div>
        <div className="mt-8 px-12 text-sm text-ever-error">
          <i>{error.message}</i>
        </div>
        <button
          className="mt-8 flex items-center rounded bg-ever-brand1 px-3 py-2"
          onClick={() => (window.location.href = OFFICIAL_PATH)}
        >
          <span className="text-sm text-ever-gray-50">
            {t("errorPage:Refresh.page")}
          </span>
        </button>
      </div>
    </div>
  );
};

export default ErrorFallback;
