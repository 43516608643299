// index.ts 文件

import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "./features/loginSlice";
import secretReducer from "./features/secretSlice";
import configMapsReducer from "./features/configMapsSlice";
import appReducer from "./features/appSlice";
import dashboardReducer from "./features/dashboardSlice";

// configureStore creates a redux data
const store = configureStore({
  // Merge multiple slices
  reducer: {
    login: loginReducer,
    secret: secretReducer,
    configMaps: configMapsReducer,
    app: appReducer,
    dashboard: dashboardReducer,
  },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
