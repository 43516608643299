// echartsConfig.ts
import { use } from "echarts/core";
import { BarChart, PieChart, LineChart } from "echarts/charts";
import {
  DatasetComponent,
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent,
  TransformComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import { LabelLayout, UniversalTransition } from "echarts/features";

import type { ComposeOption } from "echarts/core";
import type {
  BarSeriesOption,
  PieSeriesOption,
  LineSeriesOption,
} from "echarts/charts";

import type {
  GridComponentOption,
  TitleComponentOption,
  TooltipComponentOption,
  LegendComponentOption,
} from "echarts/components";

export type BarECOption = ComposeOption<
  | BarSeriesOption
  | TitleComponentOption
  | TooltipComponentOption
  | GridComponentOption
>;

export type PieECOption = ComposeOption<
  | PieSeriesOption
  | TitleComponentOption
  | TooltipComponentOption
  | GridComponentOption
  | LegendComponentOption
>;

export type LineECOption = ComposeOption<
  | LineSeriesOption
  | TitleComponentOption
  | TooltipComponentOption
  | GridComponentOption
>;

export const initEcharts = () => {
  use([
    GridComponent,
    LegendComponent,
    BarChart,
    PieChart,
    LineChart,
    CanvasRenderer,
    TitleComponent,
    TooltipComponent,
    DatasetComponent,
    TransformComponent,
    LabelLayout,
    UniversalTransition,
  ]);
};
