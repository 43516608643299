/* tslint:disable */
/* eslint-disable */
/**
 * everai/apps/v1/worker.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VolumeVolumeItem
 */
export interface VolumeVolumeItem {
    /**
     * 
     * @type {string}
     * @memberof VolumeVolumeItem
     */
    volume?: string;
}

/**
 * Check if a given object implements the VolumeVolumeItem interface.
 */
export function instanceOfVolumeVolumeItem(value: object): boolean {
    return true;
}

export function VolumeVolumeItemFromJSON(json: any): VolumeVolumeItem {
    return VolumeVolumeItemFromJSONTyped(json, false);
}

export function VolumeVolumeItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): VolumeVolumeItem {
    if (json == null) {
        return json;
    }
    return {
        
        'volume': json['volume'] == null ? undefined : json['volume'],
    };
}

export function VolumeVolumeItemToJSON(value?: VolumeVolumeItem | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'volume': value['volume'],
    };
}

