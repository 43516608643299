/* tslint:disable */
/* eslint-disable */
/**
 * everai/apps/v1/worker.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { V1AppV1Alpha1 } from './V1AppV1Alpha1';
import {
    V1AppV1Alpha1FromJSON,
    V1AppV1Alpha1FromJSONTyped,
    V1AppV1Alpha1ToJSON,
} from './V1AppV1Alpha1';

/**
 * 
 * @export
 * @interface V1App
 */
export interface V1App {
    /**
     * 
     * @type {V1AppV1Alpha1}
     * @memberof V1App
     */
    appV1alpha1?: V1AppV1Alpha1;
}

/**
 * Check if a given object implements the V1App interface.
 */
export function instanceOfV1App(value: object): boolean {
    return true;
}

export function V1AppFromJSON(json: any): V1App {
    return V1AppFromJSONTyped(json, false);
}

export function V1AppFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1App {
    if (json == null) {
        return json;
    }
    return {
        
        'appV1alpha1': json['appV1alpha1'] == null ? undefined : V1AppV1Alpha1FromJSON(json['appV1alpha1']),
    };
}

export function V1AppToJSON(value?: V1App | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'appV1alpha1': V1AppV1Alpha1ToJSON(value['appV1alpha1']),
    };
}

