/* tslint:disable */
/* eslint-disable */
/**
 * everai/apps/v1/worker.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { VolumeItem } from './VolumeItem';
import {
    VolumeItemFromJSON,
    VolumeItemFromJSONTyped,
    VolumeItemToJSON,
} from './VolumeItem';

/**
 * 
 * @export
 * @interface VolumeConfigMapItem
 */
export interface VolumeConfigMapItem {
    /**
     * 
     * @type {string}
     * @memberof VolumeConfigMapItem
     */
    name?: string;
    /**
     * 
     * @type {Array<VolumeItem>}
     * @memberof VolumeConfigMapItem
     */
    items?: Array<VolumeItem>;
}

/**
 * Check if a given object implements the VolumeConfigMapItem interface.
 */
export function instanceOfVolumeConfigMapItem(value: object): boolean {
    return true;
}

export function VolumeConfigMapItemFromJSON(json: any): VolumeConfigMapItem {
    return VolumeConfigMapItemFromJSONTyped(json, false);
}

export function VolumeConfigMapItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): VolumeConfigMapItem {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'items': json['items'] == null ? undefined : ((json['items'] as Array<any>).map(VolumeItemFromJSON)),
    };
}

export function VolumeConfigMapItemToJSON(value?: VolumeConfigMapItem | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'items': value['items'] == null ? undefined : ((value['items'] as Array<any>).map(VolumeItemToJSON)),
    };
}

