/* tslint:disable */
/* eslint-disable */
/**
 * everai/apps/v1/worker.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Commonv1Value } from './Commonv1Value';
import {
    Commonv1ValueFromJSON,
    Commonv1ValueFromJSONTyped,
    Commonv1ValueToJSON,
} from './Commonv1Value';

/**
 * 
 * @export
 * @interface V1Environment
 */
export interface V1Environment {
    /**
     * 
     * @type {string}
     * @memberof V1Environment
     */
    name?: string;
    /**
     * 
     * @type {Commonv1Value}
     * @memberof V1Environment
     */
    envValue?: Commonv1Value;
}

/**
 * Check if a given object implements the V1Environment interface.
 */
export function instanceOfV1Environment(value: object): boolean {
    return true;
}

export function V1EnvironmentFromJSON(json: any): V1Environment {
    return V1EnvironmentFromJSONTyped(json, false);
}

export function V1EnvironmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1Environment {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'envValue': json['envValue'] == null ? undefined : Commonv1ValueFromJSON(json['envValue']),
    };
}

export function V1EnvironmentToJSON(value?: V1Environment | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'envValue': Commonv1ValueToJSON(value['envValue']),
    };
}

