/* tslint:disable */
/* eslint-disable */
/**
 * everai/apps/v1/worker.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface V1Event
 */
export interface V1Event {
    /**
     * 
     * @type {string}
     * @memberof V1Event
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof V1Event
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof V1Event
     */
    from?: string;
    /**
     * 
     * @type {Date}
     * @memberof V1Event
     */
    createdAt?: Date;
}

/**
 * Check if a given object implements the V1Event interface.
 */
export function instanceOfV1Event(value: object): boolean {
    return true;
}

export function V1EventFromJSON(json: any): V1Event {
    return V1EventFromJSONTyped(json, false);
}

export function V1EventFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1Event {
    if (json == null) {
        return json;
    }
    return {
        
        'type': json['type'] == null ? undefined : json['type'],
        'message': json['message'] == null ? undefined : json['message'],
        'from': json['from'] == null ? undefined : json['from'],
        'createdAt': json['createdAt'] == null ? undefined : (new Date(json['createdAt'])),
    };
}

export function V1EventToJSON(value?: V1Event | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': value['type'],
        'message': value['message'],
        'from': value['from'],
        'createdAt': value['createdAt'] == null ? undefined : ((value['createdAt']).toISOString()),
    };
}

