// counterSlice.ts 文件

import { V1Configmap } from "@/services/configmaps";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface configMapsState {
  configMapsList: V1Configmap[];
}

const initialState: configMapsState = {
  configMapsList: [],
};

export const configMapsSlice = createSlice({
  name: "configMaps",
  initialState,
  reducers: {
    changeConfigMapsListAction(state, action: PayloadAction<V1Configmap[]>) {
      console.log("changeConfigMapsListAction", action.payload);
      state.configMapsList = action.payload;
    },
  },
});
export const { changeConfigMapsListAction } = configMapsSlice.actions;
export default configMapsSlice.reducer;
