/* tslint:disable */
/* eslint-disable */
/**
 * everai/apps/v1/worker.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { V1ValueFrom } from './V1ValueFrom';
import {
    V1ValueFromFromJSON,
    V1ValueFromFromJSONTyped,
    V1ValueFromToJSON,
} from './V1ValueFrom';

/**
 * 
 * @export
 * @interface Commonv1Value
 */
export interface Commonv1Value {
    /**
     * 
     * @type {string}
     * @memberof Commonv1Value
     */
    value?: string;
    /**
     * 
     * @type {V1ValueFrom}
     * @memberof Commonv1Value
     */
    valueFrom?: V1ValueFrom;
}

/**
 * Check if a given object implements the Commonv1Value interface.
 */
export function instanceOfCommonv1Value(value: object): boolean {
    return true;
}

export function Commonv1ValueFromJSON(json: any): Commonv1Value {
    return Commonv1ValueFromJSONTyped(json, false);
}

export function Commonv1ValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): Commonv1Value {
    if (json == null) {
        return json;
    }
    return {
        
        'value': json['value'] == null ? undefined : json['value'],
        'valueFrom': json['valueFrom'] == null ? undefined : V1ValueFromFromJSON(json['valueFrom']),
    };
}

export function Commonv1ValueToJSON(value?: Commonv1Value | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'value': value['value'],
        'valueFrom': V1ValueFromToJSON(value['valueFrom']),
    };
}

