/* tslint:disable */
/* eslint-disable */
/**
 * everai/apps/v1/worker.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface V1Metadata
 */
export interface V1Metadata {
    /**
     * 
     * @type {string}
     * @memberof V1Metadata
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof V1Metadata
     */
    namespace?: string;
    /**
     * 
     * @type {string}
     * @memberof V1Metadata
     */
    resourceVersion?: string;
    /**
     * 
     * @type {string}
     * @memberof V1Metadata
     */
    uid?: string;
    /**
     * 
     * @type {Date}
     * @memberof V1Metadata
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof V1Metadata
     */
    updatedAt?: Date;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof V1Metadata
     */
    labels?: { [key: string]: string; };
}

/**
 * Check if a given object implements the V1Metadata interface.
 */
export function instanceOfV1Metadata(value: object): boolean {
    if (!('name' in value)) return false;
    return true;
}

export function V1MetadataFromJSON(json: any): V1Metadata {
    return V1MetadataFromJSONTyped(json, false);
}

export function V1MetadataFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1Metadata {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'namespace': json['namespace'] == null ? undefined : json['namespace'],
        'resourceVersion': json['resourceVersion'] == null ? undefined : json['resourceVersion'],
        'uid': json['uid'] == null ? undefined : json['uid'],
        'createdAt': json['createdAt'] == null ? undefined : (new Date(json['createdAt'])),
        'updatedAt': json['updatedAt'] == null ? undefined : (new Date(json['updatedAt'])),
        'labels': json['labels'] == null ? undefined : json['labels'],
    };
}

export function V1MetadataToJSON(value?: V1Metadata | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'namespace': value['namespace'],
        'resourceVersion': value['resourceVersion'],
        'uid': value['uid'],
        'createdAt': value['createdAt'] == null ? undefined : ((value['createdAt']).toISOString()),
        'updatedAt': value['updatedAt'] == null ? undefined : ((value['updatedAt']).toISOString()),
        'labels': value['labels'],
    };
}

