/* tslint:disable */
/* eslint-disable */
/**
 * everai/apps/v1/worker.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { V1AppStatusV1Alpha1Status } from './V1AppStatusV1Alpha1Status';
import {
    V1AppStatusV1Alpha1StatusFromJSON,
    V1AppStatusV1Alpha1StatusFromJSONTyped,
    V1AppStatusV1Alpha1StatusToJSON,
} from './V1AppStatusV1Alpha1Status';

/**
 * 
 * @export
 * @interface V1AppStatusV1Alpha1
 */
export interface V1AppStatusV1Alpha1 {
    /**
     * 
     * @type {number}
     * @memberof V1AppStatusV1Alpha1
     */
    desiredWorker?: number;
    /**
     * 
     * @type {number}
     * @memberof V1AppStatusV1Alpha1
     */
    readyWorker?: number;
    /**
     * 
     * @type {V1AppStatusV1Alpha1Status}
     * @memberof V1AppStatusV1Alpha1
     */
    status?: V1AppStatusV1Alpha1Status;
}

/**
 * Check if a given object implements the V1AppStatusV1Alpha1 interface.
 */
export function instanceOfV1AppStatusV1Alpha1(value: object): boolean {
    return true;
}

export function V1AppStatusV1Alpha1FromJSON(json: any): V1AppStatusV1Alpha1 {
    return V1AppStatusV1Alpha1FromJSONTyped(json, false);
}

export function V1AppStatusV1Alpha1FromJSONTyped(json: any, ignoreDiscriminator: boolean): V1AppStatusV1Alpha1 {
    if (json == null) {
        return json;
    }
    return {
        
        'desiredWorker': json['desiredWorker'] == null ? undefined : json['desiredWorker'],
        'readyWorker': json['readyWorker'] == null ? undefined : json['readyWorker'],
        'status': json['status'] == null ? undefined : V1AppStatusV1Alpha1StatusFromJSON(json['status']),
    };
}

export function V1AppStatusV1Alpha1ToJSON(value?: V1AppStatusV1Alpha1 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'desiredWorker': value['desiredWorker'],
        'readyWorker': value['readyWorker'],
        'status': V1AppStatusV1Alpha1StatusToJSON(value['status']),
    };
}

