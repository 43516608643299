/* tslint:disable */
/* eslint-disable */
/**
 * everai/apps/v1/worker.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { WorkerWorkerDetailStatus } from './WorkerWorkerDetailStatus';
import {
    WorkerWorkerDetailStatusFromJSON,
    WorkerWorkerDetailStatusFromJSONTyped,
    WorkerWorkerDetailStatusToJSON,
} from './WorkerWorkerDetailStatus';
import type { WorkerWorkerStatus } from './WorkerWorkerStatus';
import {
    WorkerWorkerStatusFromJSON,
    WorkerWorkerStatusFromJSONTyped,
    WorkerWorkerStatusToJSON,
} from './WorkerWorkerStatus';

/**
 * 
 * @export
 * @interface V1Worker
 */
export interface V1Worker {
    /**
     * 
     * @type {string}
     * @memberof V1Worker
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof V1Worker
     */
    deviceId?: string;
    /**
     * 
     * @type {WorkerWorkerStatus}
     * @memberof V1Worker
     */
    status?: WorkerWorkerStatus;
    /**
     * 
     * @type {WorkerWorkerDetailStatus}
     * @memberof V1Worker
     */
    detailStatus?: WorkerWorkerDetailStatus;
    /**
     * 
     * @type {Date}
     * @memberof V1Worker
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof V1Worker
     */
    deletedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof V1Worker
     */
    launchAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof V1Worker
     */
    lastServeAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof V1Worker
     */
    successCount?: number;
    /**
     * 
     * @type {number}
     * @memberof V1Worker
     */
    failedCount?: number;
    /**
     * 
     * @type {number}
     * @memberof V1Worker
     */
    sessionNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof V1Worker
     */
    CPUs?: number;
    /**
     * 
     * @type {number}
     * @memberof V1Worker
     */
    memory?: number;
    /**
     * 
     * @type {string}
     * @memberof V1Worker
     */
    GPUName?: string;
    /**
     * 
     * @type {number}
     * @memberof V1Worker
     */
    GPUs?: number;
    /**
     * 
     * @type {string}
     * @memberof V1Worker
     */
    appId?: string;
    /**
     * 
     * @type {string}
     * @memberof V1Worker
     */
    appRevision?: string;
    /**
     * 
     * @type {string}
     * @memberof V1Worker
     */
    replaceWorkerId?: string;
    /**
     * 
     * @type {string}
     * @memberof V1Worker
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof V1Worker
     */
    requestId?: string;
    /**
     * 
     * @type {string}
     * @memberof V1Worker
     */
    gpuModel?: string;
    /**
     * 
     * @type {number}
     * @memberof V1Worker
     */
    gpuNum?: number;
    /**
     * 
     * @type {string}
     * @memberof V1Worker
     */
    stopAt?: string;
    /**
     * 
     * @type {string}
     * @memberof V1Worker
     */
    devicePublicAddress?: string;
}

/**
 * Check if a given object implements the V1Worker interface.
 */
export function instanceOfV1Worker(value: object): boolean {
    return true;
}

export function V1WorkerFromJSON(json: any): V1Worker {
    return V1WorkerFromJSONTyped(json, false);
}

export function V1WorkerFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1Worker {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'deviceId': json['deviceId'] == null ? undefined : json['deviceId'],
        'status': json['status'] == null ? undefined : WorkerWorkerStatusFromJSON(json['status']),
        'detailStatus': json['detailStatus'] == null ? undefined : WorkerWorkerDetailStatusFromJSON(json['detailStatus']),
        'createdAt': json['createdAt'] == null ? undefined : (new Date(json['createdAt'])),
        'deletedAt': json['deletedAt'] == null ? undefined : (new Date(json['deletedAt'])),
        'launchAt': json['launchAt'] == null ? undefined : (new Date(json['launchAt'])),
        'lastServeAt': json['lastServeAt'] == null ? undefined : (new Date(json['lastServeAt'])),
        'successCount': json['successCount'] == null ? undefined : json['successCount'],
        'failedCount': json['failedCount'] == null ? undefined : json['failedCount'],
        'sessionNumber': json['sessionNumber'] == null ? undefined : json['sessionNumber'],
        'CPUs': json['CPUs'] == null ? undefined : json['CPUs'],
        'memory': json['memory'] == null ? undefined : json['memory'],
        'GPUName': json['GPUName'] == null ? undefined : json['GPUName'],
        'GPUs': json['GPUs'] == null ? undefined : json['GPUs'],
        'appId': json['appId'] == null ? undefined : json['appId'],
        'appRevision': json['appRevision'] == null ? undefined : json['appRevision'],
        'replaceWorkerId': json['replaceWorkerId'] == null ? undefined : json['replaceWorkerId'],
        'userId': json['userId'] == null ? undefined : json['userId'],
        'requestId': json['requestId'] == null ? undefined : json['requestId'],
        'gpuModel': json['gpuModel'] == null ? undefined : json['gpuModel'],
        'gpuNum': json['gpuNum'] == null ? undefined : json['gpuNum'],
        'stopAt': json['stopAt'] == null ? undefined : json['stopAt'],
        'devicePublicAddress': json['devicePublicAddress'] == null ? undefined : json['devicePublicAddress'],
    };
}

export function V1WorkerToJSON(value?: V1Worker | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'deviceId': value['deviceId'],
        'status': WorkerWorkerStatusToJSON(value['status']),
        'detailStatus': WorkerWorkerDetailStatusToJSON(value['detailStatus']),
        'createdAt': value['createdAt'] == null ? undefined : ((value['createdAt']).toISOString()),
        'deletedAt': value['deletedAt'] == null ? undefined : ((value['deletedAt']).toISOString()),
        'launchAt': value['launchAt'] == null ? undefined : ((value['launchAt']).toISOString()),
        'lastServeAt': value['lastServeAt'] == null ? undefined : ((value['lastServeAt']).toISOString()),
        'successCount': value['successCount'],
        'failedCount': value['failedCount'],
        'sessionNumber': value['sessionNumber'],
        'CPUs': value['CPUs'],
        'memory': value['memory'],
        'GPUName': value['GPUName'],
        'GPUs': value['GPUs'],
        'appId': value['appId'],
        'appRevision': value['appRevision'],
        'replaceWorkerId': value['replaceWorkerId'],
        'userId': value['userId'],
        'requestId': value['requestId'],
        'gpuModel': value['gpuModel'],
        'gpuNum': value['gpuNum'],
        'stopAt': value['stopAt'],
        'devicePublicAddress': value['devicePublicAddress'],
    };
}

