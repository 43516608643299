/* tslint:disable */
/* eslint-disable */
/**
 * everai/apps/v1/worker.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { V1Namespace } from './V1Namespace';
import {
    V1NamespaceFromJSON,
    V1NamespaceFromJSONTyped,
    V1NamespaceToJSON,
} from './V1Namespace';

/**
 * 
 * @export
 * @interface Namespacesv1ListResponse
 */
export interface Namespacesv1ListResponse {
    /**
     * 
     * @type {Array<V1Namespace>}
     * @memberof Namespacesv1ListResponse
     */
    namespaces?: Array<V1Namespace>;
}

/**
 * Check if a given object implements the Namespacesv1ListResponse interface.
 */
export function instanceOfNamespacesv1ListResponse(value: object): boolean {
    return true;
}

export function Namespacesv1ListResponseFromJSON(json: any): Namespacesv1ListResponse {
    return Namespacesv1ListResponseFromJSONTyped(json, false);
}

export function Namespacesv1ListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): Namespacesv1ListResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'namespaces': json['namespaces'] == null ? undefined : ((json['namespaces'] as Array<any>).map(V1NamespaceFromJSON)),
    };
}

export function Namespacesv1ListResponseToJSON(value?: Namespacesv1ListResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'namespaces': value['namespaces'] == null ? undefined : ((value['namespaces'] as Array<any>).map(V1NamespaceToJSON)),
    };
}

