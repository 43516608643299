/* tslint:disable */
/* eslint-disable */
/**
 * everai/apps/v1/worker.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { V1App } from './V1App';
import {
    V1AppFromJSON,
    V1AppFromJSONTyped,
    V1AppToJSON,
} from './V1App';

/**
 * 
 * @export
 * @interface Appsv1ListResponse
 */
export interface Appsv1ListResponse {
    /**
     * 
     * @type {Array<V1App>}
     * @memberof Appsv1ListResponse
     */
    apps?: Array<V1App>;
}

/**
 * Check if a given object implements the Appsv1ListResponse interface.
 */
export function instanceOfAppsv1ListResponse(value: object): boolean {
    return true;
}

export function Appsv1ListResponseFromJSON(json: any): Appsv1ListResponse {
    return Appsv1ListResponseFromJSONTyped(json, false);
}

export function Appsv1ListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): Appsv1ListResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'apps': json['apps'] == null ? undefined : ((json['apps'] as Array<any>).map(V1AppFromJSON)),
    };
}

export function Appsv1ListResponseToJSON(value?: Appsv1ListResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'apps': value['apps'] == null ? undefined : ((value['apps'] as Array<any>).map(V1AppToJSON)),
    };
}

