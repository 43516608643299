/* tslint:disable */
/* eslint-disable */
/**
 * everai/apps/v1/worker.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { V1Action } from './V1Action';
import {
    V1ActionFromJSON,
    V1ActionFromJSONTyped,
    V1ActionToJSON,
} from './V1Action';

/**
 * 
 * @export
 * @interface V1LifeCycle
 */
export interface V1LifeCycle {
    /**
     * 
     * @type {V1Action}
     * @memberof V1LifeCycle
     */
    postStart?: V1Action;
}

/**
 * Check if a given object implements the V1LifeCycle interface.
 */
export function instanceOfV1LifeCycle(value: object): boolean {
    return true;
}

export function V1LifeCycleFromJSON(json: any): V1LifeCycle {
    return V1LifeCycleFromJSONTyped(json, false);
}

export function V1LifeCycleFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1LifeCycle {
    if (json == null) {
        return json;
    }
    return {
        
        'postStart': json['postStart'] == null ? undefined : V1ActionFromJSON(json['postStart']),
    };
}

export function V1LifeCycleToJSON(value?: V1LifeCycle | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'postStart': V1ActionToJSON(value['postStart']),
    };
}

