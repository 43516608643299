import { RequestStatisticListType } from "@/pages/Application/RequestStatistics";
import { V1App, V1AppStatusV1Alpha1Status } from "@/services/application";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { t } from "i18next";

export interface Tab {
  name: string;
  current: boolean;
  itemNumber: number | string;
  status: V1AppStatusV1Alpha1Status;
  iconName: string;
}

interface appState {
  appsList: V1App[];
  allAppsList: V1App[];
  currentAppInfo: V1App;
  tabs: Tab[];
  currentListDetail: RequestStatisticListType;
}

const initialState: appState = {
  appsList: [],
  allAppsList: [],
  currentAppInfo: {},
  currentListDetail: {},
  tabs: [
    // {
    //   name: t("Init.Apps"),
    //   current: true,
    //   itemNumber: 0,
    //   status: V1AppStatus.Init,
    //   icon: (props: { className: string | undefined }) => (
    //     <InitIcon className={props.className} />
    //   ),
    // },
    // {
    //   name: t("Preparing.Apps"),
    //   current: false,
    //   itemNumber: 0,
    //   status: V1AppStatus.Preparing,
    //   icon: (props: { className: string | undefined }) => (
    //     <PrepareIcon className={props.className} />
    //   ),
    // },
    {
      name: t("application:Deployed.Apps"),
      current: true,
      itemNumber: 0,
      status: V1AppStatusV1Alpha1Status.Deployed,
      iconName: "DeployIcon",
    },
    {
      name: t("application:Pause.Apps"),
      current: false,
      itemNumber: 0,
      status: V1AppStatusV1Alpha1Status.Paused,
      iconName: "PauseIcon",
    },
  ],
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    changeAppListAction(state, action: PayloadAction<V1App[]>) {
      // console.log("changeAppListAction", action.payload);
      state.appsList = action.payload;
    },
    changeAllAppListAction(state, action: PayloadAction<V1App[]>) {
      // console.log("changeAllAppListAction", action.payload);
      state.allAppsList = action.payload;
    },
    changeCurrentAppInfoAction(state, action: PayloadAction<V1App>) {
      // console.log("changeCurrentAppInfoAction", action.payload);
      state.currentAppInfo = action.payload;
    },
    changeTabAction(state, action: PayloadAction<Tab[]>) {
      // console.log("changeTabAction", action.payload);
      state.tabs = action.payload;
    },
    changeAppListDetailAction(
      state,
      action: PayloadAction<RequestStatisticListType>,
    ) {
      // console.log("changeAppListDetailAction", action.payload);
      state.currentListDetail = action.payload;
    },
  },
});

export const {
  changeAppListAction,
  changeAllAppListAction,
  changeCurrentAppInfoAction,
  changeTabAction,
  changeAppListDetailAction,
} = appSlice.actions;

export default appSlice.reducer;
