// counterSlice.ts 文件

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { V1Secret } from "../../services/secrets";

interface secretState {
  secretList: V1Secret[];
}

const initialState: secretState = {
  secretList: [],
};

export const secretSlice = createSlice({
  name: "secret",
  initialState,
  reducers: {
    changeSecretListAction(state, action: PayloadAction<V1Secret[]>) {
      console.log("changeSecretListAction", action.payload);
      state.secretList = action.payload;
    },
  },
});
export const { changeSecretListAction } = secretSlice.actions;
export default secretSlice.reducer;
