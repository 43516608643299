/* tslint:disable */
/* eslint-disable */
/**
 * everai/apps/v1/worker.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Namespacesv1ListResponse,
  V1Namespace,
} from '../models/index';
import {
    Namespacesv1ListResponseFromJSON,
    Namespacesv1ListResponseToJSON,
    V1NamespaceFromJSON,
    V1NamespaceToJSON,
} from '../models/index';

export interface CreateNamespaceRequest {
    namespace: Omit<V1Namespace, 'createdAt'>;
}

export interface DeleteNamespaceRequest {
    name: string;
}

export interface GetNamespaceRequest {
    name: string;
}

/**
 * NamespaceServiceApi - interface
 * 
 * @export
 * @interface NamespaceServiceApiInterface
 */
export interface NamespaceServiceApiInterface {
    /**
     * 
     * @summary Create an namespace
     * @param {V1Namespace} namespace 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NamespaceServiceApiInterface
     */
    createNamespaceRaw(requestParameters: CreateNamespaceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<V1Namespace>>;

    /**
     * Create an namespace
     */
    createNamespace(requestParameters: CreateNamespaceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<V1Namespace>;

    /**
     * 
     * @summary Delete namespace
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NamespaceServiceApiInterface
     */
    deleteNamespaceRaw(requestParameters: DeleteNamespaceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>>;

    /**
     * Delete namespace
     */
    deleteNamespace(requestParameters: DeleteNamespaceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object>;

    /**
     * 
     * @summary Get namespace
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NamespaceServiceApiInterface
     */
    getNamespaceRaw(requestParameters: GetNamespaceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<V1Namespace>>;

    /**
     * Get namespace
     */
    getNamespace(requestParameters: GetNamespaceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<V1Namespace>;

    /**
     * 
     * @summary List namespaces
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NamespaceServiceApiInterface
     */
    listNamespacesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Namespacesv1ListResponse>>;

    /**
     * List namespaces
     */
    listNamespaces(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Namespacesv1ListResponse>;

}

/**
 * 
 */
export class NamespaceServiceApi extends runtime.BaseAPI implements NamespaceServiceApiInterface {

    /**
     * Create an namespace
     */
    async createNamespaceRaw(requestParameters: CreateNamespaceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<V1Namespace>> {
        if (requestParameters['namespace'] == null) {
            throw new runtime.RequiredError(
                'namespace',
                'Required parameter "namespace" was null or undefined when calling createNamespace().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/namespaces/v1/namespaces`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: V1NamespaceToJSON(requestParameters['namespace']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => V1NamespaceFromJSON(jsonValue));
    }

    /**
     * Create an namespace
     */
    async createNamespace(requestParameters: CreateNamespaceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<V1Namespace> {
        const response = await this.createNamespaceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete namespace
     */
    async deleteNamespaceRaw(requestParameters: DeleteNamespaceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['name'] == null) {
            throw new runtime.RequiredError(
                'name',
                'Required parameter "name" was null or undefined when calling deleteNamespace().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/namespaces/v1/namespaces/{name}`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters['name']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Delete namespace
     */
    async deleteNamespace(requestParameters: DeleteNamespaceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.deleteNamespaceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get namespace
     */
    async getNamespaceRaw(requestParameters: GetNamespaceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<V1Namespace>> {
        if (requestParameters['name'] == null) {
            throw new runtime.RequiredError(
                'name',
                'Required parameter "name" was null or undefined when calling getNamespace().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/namespaces/v1/namespaces/{name}`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters['name']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => V1NamespaceFromJSON(jsonValue));
    }

    /**
     * Get namespace
     */
    async getNamespace(requestParameters: GetNamespaceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<V1Namespace> {
        const response = await this.getNamespaceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List namespaces
     */
    async listNamespacesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Namespacesv1ListResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/namespaces/v1/namespaces`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => Namespacesv1ListResponseFromJSON(jsonValue));
    }

    /**
     * List namespaces
     */
    async listNamespaces(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Namespacesv1ListResponse> {
        const response = await this.listNamespacesRaw(initOverrides);
        return await response.value();
    }

}
