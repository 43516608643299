/* tslint:disable */
/* eslint-disable */
/**
 * everai/apps/v1/worker.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { V1Event } from './V1Event';
import {
    V1EventFromJSON,
    V1EventFromJSONTyped,
    V1EventToJSON,
} from './V1Event';

/**
 * 
 * @export
 * @interface V1ListAppEventsResponse
 */
export interface V1ListAppEventsResponse {
    /**
     * 
     * @type {Array<V1Event>}
     * @memberof V1ListAppEventsResponse
     */
    events?: Array<V1Event>;
}

/**
 * Check if a given object implements the V1ListAppEventsResponse interface.
 */
export function instanceOfV1ListAppEventsResponse(value: object): boolean {
    return true;
}

export function V1ListAppEventsResponseFromJSON(json: any): V1ListAppEventsResponse {
    return V1ListAppEventsResponseFromJSONTyped(json, false);
}

export function V1ListAppEventsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1ListAppEventsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'events': json['events'] == null ? undefined : ((json['events'] as Array<any>).map(V1EventFromJSON)),
    };
}

export function V1ListAppEventsResponseToJSON(value?: V1ListAppEventsResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'events': value['events'] == null ? undefined : ((value['events'] as Array<any>).map(V1EventToJSON)),
    };
}

