/* tslint:disable */
/* eslint-disable */
/**
 * everai/apps/v1/worker.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { V1AppSpecV1Alpha1 } from './V1AppSpecV1Alpha1';
import {
    V1AppSpecV1Alpha1FromJSON,
    V1AppSpecV1Alpha1FromJSONTyped,
    V1AppSpecV1Alpha1ToJSON,
} from './V1AppSpecV1Alpha1';
import type { V1AppStatusV1Alpha1 } from './V1AppStatusV1Alpha1';
import {
    V1AppStatusV1Alpha1FromJSON,
    V1AppStatusV1Alpha1FromJSONTyped,
    V1AppStatusV1Alpha1ToJSON,
} from './V1AppStatusV1Alpha1';
import type { V1Metadata } from './V1Metadata';
import {
    V1MetadataFromJSON,
    V1MetadataFromJSONTyped,
    V1MetadataToJSON,
} from './V1Metadata';

/**
 * 
 * @export
 * @interface V1AppV1Alpha1
 */
export interface V1AppV1Alpha1 {
    /**
     * 
     * @type {V1Metadata}
     * @memberof V1AppV1Alpha1
     */
    metadata?: V1Metadata;
    /**
     * 
     * @type {V1AppSpecV1Alpha1}
     * @memberof V1AppV1Alpha1
     */
    spec?: V1AppSpecV1Alpha1;
    /**
     * 
     * @type {V1AppStatusV1Alpha1}
     * @memberof V1AppV1Alpha1
     */
    status?: V1AppStatusV1Alpha1;
}

/**
 * Check if a given object implements the V1AppV1Alpha1 interface.
 */
export function instanceOfV1AppV1Alpha1(value: object): boolean {
    return true;
}

export function V1AppV1Alpha1FromJSON(json: any): V1AppV1Alpha1 {
    return V1AppV1Alpha1FromJSONTyped(json, false);
}

export function V1AppV1Alpha1FromJSONTyped(json: any, ignoreDiscriminator: boolean): V1AppV1Alpha1 {
    if (json == null) {
        return json;
    }
    return {
        
        'metadata': json['metadata'] == null ? undefined : V1MetadataFromJSON(json['metadata']),
        'spec': json['spec'] == null ? undefined : V1AppSpecV1Alpha1FromJSON(json['spec']),
        'status': json['status'] == null ? undefined : V1AppStatusV1Alpha1FromJSON(json['status']),
    };
}

export function V1AppV1Alpha1ToJSON(value?: V1AppV1Alpha1 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'metadata': V1MetadataToJSON(value['metadata']),
        'spec': V1AppSpecV1Alpha1ToJSON(value['spec']),
        'status': V1AppStatusV1Alpha1ToJSON(value['status']),
    };
}

